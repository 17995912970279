define("ember-light-table/templates/components/lt-body", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 6
              },
              "end": {
                "line": 12,
                "column": 6
              }
            },
            "moduleName": "modules/ember-light-table/templates/components/lt-body.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "yield", [["get", "columns", ["loc", [null, [11, 16], [11, 23]]], 0, 0, 0, 0], ["get", "rows", ["loc", [null, [11, 24], [11, 28]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 8], [11, 30]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 8
                },
                "end": {
                  "line": 27,
                  "column": 8
                }
              },
              "moduleName": "modules/ember-light-table/templates/components/lt-body.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "lt-row", [["get", "row", ["loc", [null, [14, 19], [14, 22]]], 0, 0, 0, 0], ["get", "columns", ["loc", [null, [14, 23], [14, 30]]], 0, 0, 0, 0]], ["table", ["subexpr", "@mut", [["get", "table", ["loc", [null, [15, 18], [15, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "tableActions", ["subexpr", "@mut", [["get", "tableActions", ["loc", [null, [16, 25], [16, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "canExpand", ["subexpr", "@mut", [["get", "canExpand", ["loc", [null, [17, 22], [17, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "canSelect", ["subexpr", "@mut", [["get", "canSelect", ["loc", [null, [18, 22], [18, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "click", ["subexpr", "action", ["onRowClick", ["get", "row", ["loc", [null, [19, 39], [19, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 18], [19, 43]]], 0, 0], "doubleClick", ["subexpr", "action", ["onRowDoubleClick", ["get", "row", ["loc", [null, [20, 51], [20, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 24], [20, 55]]], 0, 0]], ["loc", [null, [14, 10], [20, 57]]], 0, 0], ["inline", "yield", [["subexpr", "hash", [], ["expanded-row", ["subexpr", "component", ["lt-spanned-row"], ["classes", "lt-expanded-row", "colspan", ["subexpr", "@mut", [["get", "colspan", ["loc", [null, [23, 87], [23, 94]]], 0, 0, 0, 0]], [], [], 0, 0], "yield", ["subexpr", "@mut", [["get", "row", ["loc", [null, [23, 101], [23, 104]]], 0, 0, 0, 0]], [], [], 0, 0], "visible", ["subexpr", "@mut", [["get", "row.expanded", ["loc", [null, [23, 113], [23, 125]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [23, 25], [23, 126]]], 0, 0], "loader", ["subexpr", "component", ["lt-spanned-row"], ["visible", false], ["loc", [null, [24, 19], [24, 61]]], 0, 0], "no-data", ["subexpr", "component", ["lt-spanned-row"], ["visible", false], ["loc", [null, [25, 20], [25, 62]]], 0, 0]], ["loc", [null, [22, 18], [26, 11]]], 0, 0], ["get", "rows", ["loc", [null, [26, 12], [26, 16]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 10], [26, 18]]], 0, 0]],
            locals: ["row"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 34,
                "column": 6
              }
            },
            "moduleName": "modules/ember-light-table/templates/components/lt-body.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "each", [["get", "rows", ["loc", [null, [13, 16], [13, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [13, 8], [27, 17]]]], ["inline", "yield", [["subexpr", "hash", [], ["loader", ["subexpr", "component", ["lt-spanned-row"], ["classes", "lt-is-loading", "colspan", ["subexpr", "@mut", [["get", "colspan", ["loc", [null, [30, 77], [30, 84]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [30, 17], [30, 85]]], 0, 0], "no-data", ["subexpr", "component", ["lt-spanned-row"], ["classes", "lt-no-data", "colspan", ["subexpr", "@mut", [["get", "colspan", ["loc", [null, [31, 75], [31, 82]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [31, 18], [31, 83]]], 0, 0], "expanded-row", ["subexpr", "component", ["lt-spanned-row"], ["visible", false], ["loc", [null, [32, 23], [32, 65]]], 0, 0]], ["loc", [null, [29, 16], [33, 9]]], 0, 0], ["get", "rows", ["loc", [null, [33, 10], [33, 14]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 8], [33, 16]]], 0, 0]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 2
              },
              "end": {
                "line": 40,
                "column": 2
              }
            },
            "moduleName": "modules/ember-light-table/templates/components/lt-body.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "lt-infinity", [], ["rows", ["subexpr", "@mut", [["get", "rows", ["loc", [null, [39, 23], [39, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "onScrolledToBottom", ["subexpr", "@mut", [["get", "onScrolledToBottom", ["loc", [null, [39, 47], [39, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "scrollBuffer", ["subexpr", "@mut", [["get", "scrollBuffer", ["loc", [null, [39, 79], [39, 91]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [39, 4], [39, 93]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 43,
              "column": 0
            }
          },
          "moduleName": "modules/ember-light-table/templates/components/lt-body.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "lt-inline lt-head");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          dom.setAttribute(el2, "class", "lt-body");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "lt-inline lt-foot");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(fragment, [7]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element0, 'id');
          morphs[1] = dom.createAttrMorph(element1, 'class');
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
          morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[4] = dom.createAttrMorph(element2, 'id');
          return morphs;
        },
        statements: [["attribute", "id", ["subexpr", "concat", [["get", "tableId", ["loc", [null, [6, 19], [6, 26]]], 0, 0, 0, 0], "_inline_head"], [], ["loc", [null, [null, null], [6, 43]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "tableClassNames", ["loc", [null, [8, 17], [8, 32]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "overwrite", ["loc", [null, [10, 12], [10, 21]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [10, 6], [34, 13]]]], ["block", "if", [["get", "onScrolledToBottom", ["loc", [null, [38, 8], [38, 26]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [38, 2], [40, 9]]]], ["attribute", "id", ["subexpr", "concat", [["get", "tableId", ["loc", [null, [42, 19], [42, 26]]], 0, 0, 0, 0], "_inline_foot"], [], ["loc", [null, [null, null], [42, 43]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 44,
            "column": 0
          }
        },
        "moduleName": "modules/ember-light-table/templates/components/lt-body.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "lt-scrollable", [], ["tagName", "", "virtualScrollbar", ["subexpr", "@mut", [["get", "useVirtualScrollbar", ["loc", [null, [3, 19], [3, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "autoHide", ["subexpr", "@mut", [["get", "autoHideScrollbar", ["loc", [null, [4, 11], [4, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [43, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});