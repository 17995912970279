define('ember-light-table/index', ['exports', 'ember-light-table/classes/Table', 'ember-light-table/classes/Column', 'ember-light-table/classes/Row'], function (exports, _emberLightTableClassesTable, _emberLightTableClassesColumn, _emberLightTableClassesRow) {
  'use strict';

  /**
   * ## Installation
   * ```shell
   * ember install ember-light-table
   * ```
   *
   * ## Looking for help?
   * If it is a bug [please open an issue on GitHub](http://github.com/offirgolan/ember-light-table/issues).
   *
   * ## Usage
   * There are two parts to this addon. The first is the [Table](../classes/Table.html) which you create with column definitions and rows, and the second is the component declaration.
   *
   * ### Table Declaration
   * The `Table` constructor accepts an array of `Columns` or column options and an array of rows.
   *
   * ```javascript
   * import Table from 'ember-light-table';
   * const table = new Table(columns, rows);
   * ```
   *
   * Here is a more real-word example
   *
   * ```javascript
   * // components/my-table.js
   * import Ember from 'ember';
   * import Table from 'ember-light-table';
   *
   * const computed = Ember.computed;
   *
   * export default Ember.Component.extend({
   *   model: null,
   *   table: null,
   *
   *   columns: computed(function() {
   *     return [{
   *       label: 'Avatar',
   *       valuePath: 'avatar',
   *       width: '60px',
   *       sortable: false,
   *       cellComponent: 'user-avatar'
   *     }, {
   *       label: 'First Name',
   *       valuePath: 'firstName',
   *       width: '150px'
   *     }, {
   *       label: 'Last Name',
   *       valuePath: 'lastName',
   *       width: '150px'
   *     }, {
   *       label: 'Address',
   *       valuePath: 'address'
   *     }, {
   *       label: 'State',
   *       valuePath: 'state'
   *     }, {
   *       label: 'Country',
   *       valuePath: 'country'
   *     }];
   *   }),
   *
   *   init() {
   *     this._super(...arguments);
   *     this.set('table', new Table(this.get('columns'), this.get('model')));
   *   }
   * });
   * ```
   *
   * For a list of possible column options, please
   * [checkout out the docs](../classes/Column.html).
   *
   * ### Component Declaration
   *
   * Now that we have our `table`, we can declare our component in our template.
   *
   * ```hbs
   * {{#light-table table as |t|}}
   *   {{t.head}}
   *
   *   {{#t.body as |body|}}
   *     {{#body.expanded-row as |row|}}
   *       Hello <b>{{row.firstName}}</b>
   *     {{/body.expanded-row}}
   *
   *     {{#if isLoading}}
   *       {{#body.loader}}
   *         Loading...
   *       {{/body.loader}}
   *     {{/if}}
   *
   *     {{#if table.isEmpty}}
   *       {{#body.no-data}}
   *         No users found.
   *       {{/body.no-data}}
   *     {{/if}}
   *   {{/t.body}}
   *
   *   {{t.foot}}
   * {{/light-table}}
   * ```
   *
   * Please note that each of these contextual components have a wide array of options so it is advised to look
   * through the documentation.
   *
   * @module Usage
   */
  exports['default'] = _emberLightTableClassesTable['default'];
  exports.Table = _emberLightTableClassesTable['default'];
  exports.Column = _emberLightTableClassesColumn['default'];
  exports.Row = _emberLightTableClassesRow['default'];
});