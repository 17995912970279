define('ember-scrollable/classes/scrollable', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _get = function get(_x2, _x3, _x4) {
    var _again = true;_function: while (_again) {
      var object = _x2,
          property = _x3,
          receiver = _x4;_again = false;if (object === null) object = Function.prototype;var desc = Object.getOwnPropertyDescriptor(object, property);if (desc === undefined) {
        var parent = Object.getPrototypeOf(object);if (parent === null) {
          return undefined;
        } else {
          _x2 = parent;_x3 = property;_x4 = receiver;_again = true;desc = parent = undefined;continue _function;
        }
      } else if ('value' in desc) {
        return desc.value;
      } else {
        var getter = desc.get;if (getter === undefined) {
          return undefined;
        }return getter.call(receiver);
      }
    }
  };

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _inherits(subClass, superClass) {
    if (typeof superClass !== 'function' && superClass !== null) {
      throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass);
    }subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } });if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var camelize = _ember['default'].String.camelize;

  var pageJumpMultp = 7 / 8;

  var Scrollable = (function () {
    function Scrollable(options) {
      _classCallCheck(this, Scrollable);

      this.scrollContentElement = options.scrollContentElement;
      this.scrollbarElement = options.scrollbarElement;
      this.handleElement = options.handleElement;
      this.contentElement = options.contentElement;

      this.width = options.width;
      this.height = options.height;
      this.scrollbarWidth = options.scrollbarWidth;
    }

    _createClass(Scrollable, [{
      key: 'startDrag',
      value: function startDrag(e) {
        this.dragOffset = this.eventOffset(e) - this.handleOffset();
      }
    }, {
      key: 'handleOffset',
      value: function handleOffset() {
        return this.handleElement.offset()[this.offsetAttr];
      }
    }, {
      key: 'handlePositionOffset',
      value: function handlePositionOffset() {
        return this.handleElement.position()[this.offsetAttr];
      }
    }, {
      key: 'scrollbarOffset',
      value: function scrollbarOffset() {
        return this.scrollbarElement.offset()[this.offsetAttr];
      }
    }, {
      key: 'scrollbarSize',
      value: function scrollbarSize() {
        return this.scrollbarElement[this.sizeAttr]();
      }
    }, {
      key: 'contentSize',
      value: function contentSize() {
        return this.contentElement[this.sizeAttr]();
      }
    }, {
      key: 'contentOuterSize',
      value: function contentOuterSize() {
        return this.contentElement[camelize('outer-' + this.sizeAttr)]();
      }
    }, {
      key: 'scrollTo',
      value: function scrollTo(scrollPos) {
        this.scrollContentElement[this.scrollOffsetAttr](scrollPos);
      }
    }, {
      key: 'scrollOffset',
      value: function scrollOffset() {
        return this.scrollContentElement[this.scrollOffsetAttr]();
      }
    }, {
      key: 'scrollContentSize',
      value: function scrollContentSize() {
        return this.scrollContentElement[this.sizeAttr]();
      }
    }, {
      key: 'drag',
      value: function drag(e) {
        var eventOffset = this.eventOffset(e);

        var dragPos = eventOffset - this.scrollbarOffset() - this.dragOffset;
        // Convert the mouse position into a percentage of the scrollbar height/width.
        var dragPerc = dragPos / this.scrollbarSize();
        // Scroll the content by the same percentage.
        var scrollPos = dragPerc * this.contentSize();

        this.scrollTo(scrollPos);
      }
    }, {
      key: 'jumpScroll',
      value: function jumpScroll(e) {
        // The content will scroll by 7/8 of a page.
        var jumpAmt = pageJumpMultp * this.scrollContentSize();

        var eventOffset = this.jumpScrollEventOffset(e);
        var handleOffset = this.handlePositionOffset();
        var scrollOffset = this.scrollOffset();

        var scrollPos = eventOffset < handleOffset ? scrollOffset - jumpAmt : scrollOffset + jumpAmt;

        this.scrollTo(scrollPos);
      }
    }, {
      key: 'update',
      value: function update() {
        var scrollOffset = this.scrollOffset();
        var contentSize = this.contentOuterSize();
        var scrollbarSize = this.scrollbarSize();
        var scrollbarRatio = scrollbarSize / contentSize;

        // Calculate new height/position of drag handle.
        // Offset of 2px allows for a small top/bottom or left/right margin around handle.
        var handleOffset = Math.round(scrollbarRatio * scrollOffset) + 2;

        var handleSize = 0;

        // check if content is scrollbar is longer than content
        if (scrollbarRatio < 1) {
          var handleSizeCalculated = Math.floor(scrollbarRatio * (scrollbarSize - 2)) - 2;
          // check if handleSize is too small
          handleSize = handleSizeCalculated < 10 ? 10 : handleSizeCalculated;
        }

        this.updateHandle(handleOffset, handleSize);
      }
    }, {
      key: 'isScrolledToBottom',
      value: function isScrolledToBottom() {
        var scrollBuffer = arguments.length <= 0 || arguments[0] === undefined ? 0 : arguments[0];

        var scrollOffset = this.scrollOffset();
        var contentSize = this.contentOuterSize();
        var scrollbarSize = this.scrollbarSize();

        return scrollOffset + scrollbarSize + scrollBuffer >= contentSize;
      }
    }, {
      key: 'remove',
      value: function remove() {
        this.scrollContentElement.width('');
        this.scrollContentElement.height('');
      }
    }, {
      key: 'isNecessary',
      get: function get() {
        return this.scrollbarSize() < this.contentOuterSize();
      }
    }]);

    return Scrollable;
  })();

  exports['default'] = Scrollable;

  var Vertical = (function (_Scrollable) {
    _inherits(Vertical, _Scrollable);

    function Vertical(options) {
      _classCallCheck(this, Vertical);

      _get(Object.getPrototypeOf(Vertical.prototype), 'constructor', this).call(this, options);

      this.scrollOffsetAttr = 'scrollTop';
      this.offsetAttr = 'top';
      this.sizeAttr = 'height';

      this.resizeScrollContent();
      this.update();
    }

    _createClass(Vertical, [{
      key: 'resizeScrollContent',
      value: function resizeScrollContent() {
        this.scrollContentElement.width(this.width + this.scrollbarWidth);
        this.scrollContentElement.height(this.height);
      }
    }, {
      key: 'eventOffset',
      value: function eventOffset(e) {
        return e.pageY;
      }
    }, {
      key: 'jumpScrollEventOffset',
      value: function jumpScrollEventOffset(e) {
        return e.originalEvent.layerY;
      }
    }, {
      key: 'updateHandle',
      value: function updateHandle(top, height) {
        this.handleElement.css({ top: top, height: height });
      }
    }]);

    return Vertical;
  })(Scrollable);

  exports.Vertical = Vertical;

  var Horizontal = (function (_Scrollable2) {
    _inherits(Horizontal, _Scrollable2);

    function Horizontal(options) {
      _classCallCheck(this, Horizontal);

      _get(Object.getPrototypeOf(Horizontal.prototype), 'constructor', this).call(this, options);

      this.scrollOffsetAttr = 'scrollLeft';
      this.offsetAttr = 'left';
      this.sizeAttr = 'width';

      this.resizeScrollContent();
      this.update();
    }

    _createClass(Horizontal, [{
      key: 'resizeScrollContent',
      value: function resizeScrollContent() {
        this.scrollContentElement.width(this.width);
        this.scrollContentElement.height(this.height + this.scrollbarWidth);
        this.contentElement.height(this.height);
      }
    }, {
      key: 'eventOffset',
      value: function eventOffset(e) {
        return e.pageX;
      }
    }, {
      key: 'jumpScrollEventOffset',
      value: function jumpScrollEventOffset(e) {
        return e.originalEvent.layerX;
      }
    }, {
      key: 'updateHandle',
      value: function updateHandle(left, width) {
        this.handleElement.css({ left: left, width: width });
      }
    }]);

    return Horizontal;
  })(Scrollable);

  exports.Horizontal = Horizontal;
});