define('ember-light-table/components/lt-body', ['exports', 'ember', 'ember-light-table/templates/components/lt-body', 'ember-light-table/utils/call-action'], function (exports, _ember, _emberLightTableTemplatesComponentsLtBody, _emberLightTableUtilsCallAction) {
  'use strict';

  var _slice = Array.prototype.slice;

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;

  /**
   * @module Light Table
   */

  /**
   * ```hbs
   * {{#light-table table as |t|}}
   *   {{#t.body multiSelect=true onRowClick=(action 'rowClicked')}}
   *     {{#body.expanded-row as |row|}}
   *       Hello <b>{{row.firstName}}</b>
   *     {{/body.expanded-row}}
   *
   *     {{#if isLoading}}
   *       {{#body.loader}}
   *         Loading...
   *       {{/body.loader}}
   *     {{/if}}
   *
   *     {{#if table.isEmpty}}
   *       {{#body.no-data}}
   *         No users found.
   *       {{/body.no-data}}
   *     {{/if}}
   *   {{/t.body}}
   * {{/light-table}}
   * ```
   *
   * @class t.body
   */

  exports['default'] = Component.extend({
    layout: _emberLightTableTemplatesComponentsLtBody['default'],
    classNames: ['lt-body-wrap'],
    classNameBindings: ['canSelect', 'multiSelect', 'canExpand'],

    /**
     * @property table
     * @type {Table}
     * @private
     */
    table: null,

    /**
     * @property sharedOptions
     * @type {Object}
     * @private
     */
    sharedOptions: null,

    /**
     * @property tableActions
     * @type {Object}
     */
    tableActions: null,

    /**
     * Allows a user to select a row on click. All this will do is apply the necessary
     * CSS classes and add the row to `table.selectedRows`. If `multiSelect` is disabled
     * only one row will be selected at a time.
     *
     * @property canSelect
     * @type {Boolean}
     * @default true
     */
    canSelect: true,

    /**
     * Allows for expanding row. This will create a new row under the row that was
     * clicked with the template provided by `body.expanded-row`.
     *
     * ```hbs
     * {{#body.expanded-row as |row|}}
     *  This is the content of the expanded row for {{row.firstName}}
     * {{/body.expanded-row}}
     * ```
     *
     * @property canExpand
     * @type {Boolean}
     * @default false
     */
    canExpand: false,

    /**
     * Allows a user to select multiple rows with the `ctrl`, `cmd`, and `shift` keys.
     * These rows can be easily accessed via `table.get('selectedRows')`
     *
     * @property multiSelect
     * @type {Boolean}
     * @default false
     */
    multiSelect: false,

    /**
     * When multiSelect is true, this property determines whether or not `ctrl`
     * (or `cmd`) is required to select additional rows, one by one. When false,
     * simply clicking on subsequent rows will select or deselect them.
     *
     * `shift` to select many consecutive rows is unaffected by this property.
     *
     * @property multiSelectRequiresKeyboard
     * @type {Boolean}
     * @default true
     */
    multiSelectRequiresKeyboard: true,

    /**
     * Hide scrollbar when not scrolling
     *
     * @property autoHideScrollbar
     * @type {Boolean}
     * @default true
     */
    autoHideScrollbar: true,

    /**
     * Allows multiple rows to be expanded at once
     *
     * @property multiRowExpansion
     * @type {Boolean}
     * @default true
     */
    multiRowExpansion: true,

    /**
     * Expand a row on click
     *
     * @property expandOnClick
     * @type {Boolean}
     * @default true
     */
    expandOnClick: true,

    /**
     * If true, the body block will yield columns and rows, allowing you
     * to define your own table body
     *
     * @property overwrite
     * @type {Boolean}
     * @default false
     */
    overwrite: false,

    /**
     * ID of main table component. Used to generate divs for ember-wormhole
     *
     * @property tableId
     * @type {String}
     * @private
     */
    tableId: null,

    /**
     * @property scrollBuffer
     * @type {Number}
     * @default 500
     */
    scrollBuffer: 500,

    /**
     * @property useVirtualScrollbar
     * @type {Boolean}
     * @default false
     * @private
     */
    useVirtualScrollbar: false,

    rows: computed.readOnly('table.visibleRows'),
    columns: computed.readOnly('table.visibleColumns'),
    colspan: computed.readOnly('columns.length'),

    _currSelectedIndex: -1,
    _prevSelectedIndex: -1,

    init: function init() {
      this._super.apply(this, arguments);

      /*
        We can only set `useVirtualScrollbar` once all contextual components have
        been initialized since fixedHeader and fixedFooter are set on t.head and t.foot
        initialization.
       */
      run.once(this, this._setupVirtualScrollbar);
    },

    _setupVirtualScrollbar: function _setupVirtualScrollbar() {
      var _get = this.get('sharedOptions');

      var fixedHeader = _get.fixedHeader;
      var fixedFooter = _get.fixedFooter;

      this.set('useVirtualScrollbar', fixedHeader || fixedFooter);
    },

    togglExpandedRow: function togglExpandedRow(row) {
      var multi = this.get('multiRowExpansion');
      var shouldExpand = !row.expanded;

      if (multi) {
        row.toggleProperty('expanded');
      } else {
        this.get('table.expandedRows').setEach('expanded', false);
        row.set('expanded', shouldExpand);
      }
    },

    actions: {
      /**
       * onRowClick action. Handles selection, and row expansion.
       * @event onRowClick
       * @param  {Row}   row The row that was clicked
       * @param  {Event}   event   The click event
       */
      onRowClick: function onRowClick(row, e) {
        var rows = this.get('table.rows');
        var multiSelect = this.get('multiSelect');
        var multiSelectRequiresKeyboard = this.get('multiSelectRequiresKeyboard');
        var canSelect = this.get('canSelect');
        var isSelected = row.get('selected');
        var currIndex = rows.indexOf(row);
        var prevIndex = this._prevSelectedIndex === -1 ? currIndex : this._prevSelectedIndex;

        this._currSelectedIndex = currIndex;
        this._prevSelectedIndex = prevIndex;

        if (canSelect) {
          if (e.shiftKey && multiSelect) {
            rows.slice(Math.min(currIndex, prevIndex), Math.max(currIndex, prevIndex) + 1).forEach(function (r) {
              return r.set('selected', !isSelected);
            });
            this._prevSelectedIndex = currIndex;
          } else if ((!multiSelectRequiresKeyboard || e.ctrlKey || e.metaKey) && multiSelect) {
            row.toggleProperty('selected');
          } else {
            this.get('table.selectedRows').setEach('selected', false);
            row.set('selected', !isSelected);

            if (this.get('canExpand') && this.get('expandOnClick')) {
              this.togglExpandedRow(row);
            }
          }
          this._prevSelectedIndex = currIndex;
        } else {
          if (this.get('canExpand') && this.get('expandOnClick')) {
            this.togglExpandedRow(row);
          }
        }

        _emberLightTableUtilsCallAction['default'].apply(undefined, [this, 'onRowClick'].concat(_slice.call(arguments)));
      },

      /**
       * onRowDoubleClick action.
       * @event onRowDoubleClick
       * @param  {Row}   row The row that was clicked
       * @param  {Event}   event   The click event
       */
      onRowDoubleClick: function onRowDoubleClick() /* row */{
        _emberLightTableUtilsCallAction['default'].apply(undefined, [this, 'onRowDoubleClick'].concat(_slice.call(arguments)));
      },

      /**
       * onScrolledToBottom action - sent when user scrolls to the bottom
       *
       * @event onScrolledToBottom
       */
      onScrolledToBottom: function onScrolledToBottom() {
        (0, _emberLightTableUtilsCallAction['default'])(this, 'onScrolledToBottom');
      }
    }
  });
});