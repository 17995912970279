define("ember-light-table/templates/components/lt-foot", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 6
              },
              "end": {
                "line": 6,
                "column": 6
              }
            },
            "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "yield", [["get", "columns", ["loc", [null, [5, 16], [5, 23]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 8], [5, 25]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 10
                },
                "end": {
                  "line": 17,
                  "column": 10
                }
              },
              "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "component", [["subexpr", "concat", ["light-table/columns/", ["get", "column.type", ["loc", [null, [9, 55], [9, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 24], [9, 67]]], 0, 0], ["get", "column", ["loc", [null, [9, 68], [9, 74]]], 0, 0, 0, 0]], ["table", ["subexpr", "@mut", [["get", "table", ["loc", [null, [10, 20], [10, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "tableActions", ["subexpr", "@mut", [["get", "tableActions", ["loc", [null, [11, 27], [11, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "sortIcons", ["subexpr", "@mut", [["get", "sortIcons", ["loc", [null, [12, 24], [12, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "resizeOnDrag", ["subexpr", "@mut", [["get", "resizeOnDrag", ["loc", [null, [13, 27], [13, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "click", ["subexpr", "action", ["onColumnClick", ["get", "column", ["loc", [null, [14, 44], [14, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 20], [14, 51]]], 0, 0], "doubleClick", ["subexpr", "action", ["onColumnDoubleClick", ["get", "column", ["loc", [null, [15, 56], [15, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 26], [15, 63]]], 0, 0], "onColumnResized", ["subexpr", "action", ["onColumnResized"], [], ["loc", [null, [16, 30], [16, 56]]], 0, 0]], ["loc", [null, [9, 12], [16, 58]]], 0, 0]],
            locals: ["column"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 6
              },
              "end": {
                "line": 19,
                "column": 6
              }
            },
            "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "columns", ["loc", [null, [8, 18], [8, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [8, 10], [17, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tfoot");
          dom.setAttribute(el2, "class", "lt-foot");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["get", "tableClassNames", ["loc", [null, [2, 17], [2, 32]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "hasBlock", ["loc", [null, [4, 12], [4, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 6], [19, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "modules/ember-light-table/templates/components/lt-foot.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "ember-wormhole", [], ["to", ["subexpr", "concat", [["get", "tableId", ["loc", [null, [1, 29], [1, 36]]], 0, 0, 0, 0], "_inline_foot"], [], ["loc", [null, [1, 21], [1, 52]]], 0, 0], "renderInPlace", ["subexpr", "@mut", [["get", "renderInPlace", ["loc", [null, [1, 67], [1, 80]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [22, 19]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});