define('ember-light-table/components/lt-column-resizer', ['exports', 'ember', 'ember-light-table/templates/components/lt-column-resizer'], function (exports, _ember, _emberLightTableTemplatesComponentsLtColumnResizer) {
  'use strict';

  var $ = _ember['default'].$;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberLightTableTemplatesComponentsLtColumnResizer['default'],
    classNameBindings: [':lt-column-resizer', 'isResizing'],
    column: null,
    resizeOnDrag: false,

    isResizing: false,
    startWidth: null,
    startX: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.__mouseMove = this._mouseMove.bind(this);
      this.__mouseUp = this._mouseUp.bind(this);

      $(document).on('mousemove', this.__mouseMove);
      $(document).on('mouseup', this.__mouseUp);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      $(document).off('mousemove', this.__mouseMove);
      $(document).off('mouseup', this.__mouseUp);
    },

    click: function click(e) {
      /*
        Prevent click events from propagating (i.e. onColumnClick)
       */
      e.preventDefault();
      e.stopPropagation();
    },

    mouseDown: function mouseDown(e) {
      var $column = this._getColumn();

      e.preventDefault();
      e.stopPropagation();

      this.setProperties({
        isResizing: true,
        startWidth: $column.width(),
        startX: e.pageX
      });
    },

    _mouseUp: function _mouseUp(e) {
      if (this.get('isResizing')) {
        e.preventDefault();
        e.stopPropagation();

        var $column = this._getColumn();

        this.set('isResizing', false);
        this.set('column.width', $column.width() + 'px');
        this.sendAction('columnResized', this.get('column.width'));
      }
    },

    _mouseMove: function _mouseMove(e) {
      if (this.get('isResizing')) {
        e.preventDefault();
        e.stopPropagation();

        var resizeOnDrag = this.get('resizeOnDrag');
        var $column = this._getColumn();

        var _getProperties = this.getProperties(['startX', 'startWidth']);

        var startX = _getProperties.startX;
        var startWidth = _getProperties.startWidth;

        var width = startWidth + (e.pageX - startX);

        if (resizeOnDrag) {
          this.set('column.width', width + 'px');
        } else {
          $column.width(width + 'px');
        }
      }
    },

    _getColumn: function _getColumn() {
      return $(this.get('element')).parent('th');
    }
  });
});